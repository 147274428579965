.answer-buttons {
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.youtube-video-container {
    margin-top: 6%;
}

.react-player {
    pointer-events: none;
    margin: 0 auto;
}

.answer-rewind {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

.tester-word-display {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    border: 1px solid black;
    border-radius: 5px;
    padding: 20px;
}

.tester-blanks {
    border: 1px solid black;
}

.display-blank-word {
    color: #ee1b1b;
    background-color: #ee1b1b;
}

.rewind-button {
    margin-right: 20px;
}

.youtube-input-form {
    margin-top: 7%;
}