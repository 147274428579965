.account-container {
    margin-top: 64px;
    height: 100vh;
    display: grid;
    grid-template-columns: 240px 1fr;
}

.sidebar {
    grid-column: 1 / 2;
}

.main-account-display {
    grid-column: 2 / 3;
}

.account-settings {
    grid-column: 2 / 3;
}

.saved-words-list {
    grid-column: 2 / 3;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    margin-top: 40px;
    margin-left: 10px;
}

.words-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

