.signup-form, .signin-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: 64px;
    width: 100%;
    background-image: url("./flagWall.jpg");
    background-size: cover;
    opacity: 1;
    text-align: center;
}

.form-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 20px;
}

.signin-button {
    margin-bottom: 5px;
}

.form-title {
    font-size: 30px;
    margin-top: 20px;
}

.switch-sign-type {
    margin-bottom: 20px;
}


