body {
    padding: 0;
    margin: 0;
}

.main-navbar {
    position: fixed;
    background-color: "#091e38";
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 5px;
    display: flex;
    justify-content: space-between;
    z-index: 4;
}

.navbar-links {
    padding-right: 20px;
    color: white;
}

.link {
    padding: 0px 10px;
    color: white;
    padding: 10px;
    transition: background .2s ease-in-out;
}

.link:hover {
    background-color: #f8f8f8;
    color: black;
}

.website-title {
    padding-left: 5px;
    color: white;
}

.feedback-button {
    padding: 15px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    transition: transform .2s ease-in-out;
    font-size: 15px;
}

.feedback-button:hover {
    transform: scale(1.1, 1.1);
}

.home-page-container {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: radial-gradient(#0e335f, #061426);
    z-index: -1;
}

.type-effect-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

.type-effect {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid orange; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    font-weight: 700;
    color: white;
    animation: 
      typing 1.0s steps(40, end),
      blink-caret .75s step-end 4000;
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }

.static-type-text {
    color: white;
}

.type-container {
    padding-left: 7px;
}

.options-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.home-options-button {
    background-color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    transition: background .2s ease-in-out;
    animation: fade-in-options-button 2.5s ease-in;
}

.home-options-button:hover {
    background-color: #5f0e33;
    color: white;
}

@keyframes fade-in-options-button {
    0% { opacity: 0% }
    100% { opacity: 100% }
}

.mobile-nav-menu {
    display: none;
}

.navbar-links-loggedin {
    display: flex;
    gap: 5px;
}

.website-logo-name {
    font-size: 20px;
    letter-spacing: 3px;
    vertical-align: center;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
    .mobile-nav-menu {
        display: inline;
    }
    .navbar-links {
        display: none;
    }
    .navbar-links-loggedin {
        display: none;
    }
    .website-logo-name {
        position: relative;
        top: 0;
        right: 25%;
        font-size: 20px;
        letter-spacing: 3px;
    }
    .app-description {
        flex-direction: column;
    }
    .bubble-container {
        display: none;
    }
    .how-works-text {
        columns: 1 / 3;
    }
}

.navbar-links-loggedin:last-of-type {
    padding-right: 20px;
}

.down-arrow {
    position: absolute;
    bottom: 0;
    margin-bottom: 30px;
}

.app-description {
    display: flex;
    background: linear-gradient(#081e3a, #4366d8);
    color: white;
}

.app-description > * {
    flex-basis: 100%;
}

.description {
    padding: 5px 10px;
}

.description-title, .description-subtext {
    text-align: center;
    letter-spacing: 1px;
    padding: 0 15px;
}

.description-number-container {
    display: flex;
    justify-content: center;
    padding: 15px 0;
}

.custom-shape-divider-top-1676042209 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1676042209 svg {
    position: relative;
    display: block;
    width: calc(124% + 1.3px);
    height: 136px;
}

.custom-shape-divider-top-1676042209 .shape-fill {
    fill: #4366D8;
}

.how-works-container {
    background: #d8b543;
    margin-top: 0;
    position: relative;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
}

.bubble-container {
    grid-column: 1 / 2;
    margin: 90px auto;
}

.how-works-text {
    grid-column: 2 / 3;
    margin: 40px auto;
}

.bubble-image {
    height: 300px;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.how-works-title {
    font-size: 30px;
}

.how-works-subtext {
    letter-spacing: 1px;
    padding-right: 20px;
    line-height: 1.7;
}

.footer-container {
    background-color: #2e270e;
    position: relative;
    display: inline-block;
    width: 100%;
}

.custom-shape-divider-top-1676045674 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1676045674 svg {
    position: relative;
    display: block;
    width: calc(128% + 1.3px);
    height: 102px;
}

.custom-shape-divider-top-1676045674 .shape-fill {
    fill: #D8B543;
}

.contact-container {
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
}

.contact-information {
    padding: 30px;
}

.contact-info, .contact {
    color: white;
}

.feedback-container {
    position: relative;
    float: right;
    padding: 30px;
}

