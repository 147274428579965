
.grading-container-paper {
    height: 90%;
    width: 90%;
    overflow: scroll;
}

.grading-table {
    width: 100%;
}

.table-row-data {
    text-align: center;
}

.table-row-correct {
    background-color: rgb(0, 255, 0, 0.3);
}

.table-row-incorrect {
    background-color: rgb(255, 0 , 0, 0.3)
}

.grade-indicator-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.animated-number-container {
    display: flex;
    gap: 5px;
    color: white;
    justify-content: center;
    background-color: #3366BA;
}

.animated-number {
    font-size: 30px;
}

.grading-list-container {
    display: flex;
    line-height: 1.6;
    flex-wrap: wrap;
    height: 60%;
    overflow: scroll;
    padding: 0 20px;
}

.grading-word-container {
    padding: 0 3px;
}

.incorrect-word-grade {
    color: red;
    text-decoration: line-through;
}

.correct-word-grade {
    color: green;
}

.grading-word-container-indicator {
    display: inline-block;
    padding: 3px 5px;
    background-color:rgb(255, 0 , 0, 0.3);
    border-radius: 10px;
    border: none;
    height: 10%;
}

.grading-word-container-correct-indicator {
    display: inline-block;
    padding: 3px 5px;
    background-color:rgb(0, 255 , 0, 0.3);
    border-radius: 10px;
    border: none;
    height: 10%;
}

.grading-container-close {
    width: 100%;
    font-size: 20px;
    margin-top: 10px;
    padding: 10px 0px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: #3366BA;
}

.grading-container {
    z-index: 999;
}