@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
    font-family: 'Poppins', 'sans-serif';
}

.input-container-wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    margin-top: 7%;
    background-color: hsl(36, 100%, 99%);
}

.language-input-container {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    justify-content: center;
}

.test-pane-container {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    width: 100%;
}

.word-bank-container {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    margin-left: 20px;
}

.youtube-video-component {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.language-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", "sans-serif";
}

.youtube-input-form {
    margin: 0 auto;
    width: 80%;
}

.youtube-input-form > select {
    margin-top: 10px;
}

.language-input-button {
    width: 100%;
    padding: 10px 0;
    margin-top: 10px;
    border: none;
    cursor: pointer;
}

.word-container {
    padding: 0 3px;
}

.word-form-container {
    padding: 0 2px;
}

.word-form-container > input {
    border: none;
    border-bottom: 2px solid black;
}

.test-pane {
    display: flex;
    line-height: 1.8;
    flex-wrap: wrap;
    height: 80%;
    overflow: scroll;
}

.correct-word {
    background-color: rgb(0, 255, 0, 0.3);
}

.incorrect-word {
    background-color: rgb(255, 0 , 0, 0.3)
}

.loading-icon {
    height: 1rem;
}

.threeDot-loader {
    height: 1rem;
}

.frequency-change {
    margin-top: 10px;
}

.choose-input-type {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.input-type-button {
    border-radius: 10px;
    border: none;
    cursor: pointer;
    padding: 10px;
    transition: background .2s ease-in-out;
    margin-bottom: 20px;
}

.input-type-button:hover {
    background-color: #06bcee;
}

.input-type-button:focus {
    background-color: #06bcee;
}

.test-pane-submit-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.test-pane-submit-button {
    padding: 10px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
    transition: background .2s ease-in-out;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.test-pane-submit-button:hover {
    background-color: rgb(0, 255, 0, 0.3);
}

.word-bank-word {
    padding: 0 10px;
}

.word-bank {
    position: sticky;
    top: 50px;
}

.word-bank-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: 50%;
    gap: 6px;
}

.youtube-video-container {
    margin-left: 10px;
    position: sticky;
    top: 50px;
    z-index: 1;

}

.youtube-video {
    width: 60%;
}


@media screen and (min-width: 0px) and (max-width: 650px) {
    .choose-input-type {
        margin-top: 55px;
    }
}

.configure-options-button {
    display: block;
    padding: 4px;
    cursor: pointer;
    margin: 5px 0px;
}

.finished-word {
    background-color: red;
}